import React, { useState } from "react"
import { Form, Button, Input } from "semantic-ui-react"
import ButtonLink from "./ButtonLink"
import jsonp from "fetch-jsonp"

const RegisterForm = props => {
  const formFields = { firstname: "", lastname: "", email: "", company: "" }

  const [registered, setRegistered] = useState(false)
  // const [registered, setRegistered] = useState(
  //   (typeof window !== "undefined" &&
  //     window.localStorage.getItem("registeredContent")) ||
  //     false
  // )

  const [registerForm, setRegisterForm] = useState(formFields)

  //Getting there with localstorage to control the form.
  //Implement a "Let me register again" button
  //Test using effects like the tutorial here:
  // https://www.robinwieruch.de/local-storage-react

  // useEffect(() => {
  //   typeof window !== "undefined" &&
  //     window.localStorage.setItem("registeredContent", registered)
  // }, [registered])

  const handleSumbit = async event => {
    event.preventDefault()
    let queryString = Object.keys(registerForm)
      .map(key => key + "=" + registerForm[key])
      .join("&")

    jsonp(`https://go.pardot.com/l/839093/2020-03-19/32x7?${queryString}`, {
      jsonpCallbackFunction: "jsonpCb",
      timeout: 10000,
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        console.log(json)
        if (json.result.status === "success") {
          setRegistered(true)
        } else {
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChange = event => {
    // console.log(registerForm)
    setRegisterForm({
      ...registerForm,
      [event.target.name]: event.target.value,
    })
  }

  const showForm = () => {
    setRegistered(current => !current)
  }

  // return registered === true ? <RenderFormSubmitted /> : <RenderForm />
  return registered !== true ? (
    <Form>
      <h3>Registration Form</h3>
      <p>
        Complete the form below to be notified when new content is released.
      </p>
      <Form.Field required>
        <label htmlFor="firstname">First Name</label>
        <Input
          placeholder="First Name"
          name="firstname"
          value={registerForm.firstname}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label htmlFor="lastname">Last Name</label>
        <Input
          placeholder="Last Name"
          name="lastname"
          value={registerForm.lastname}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field required>
        <label htmlFor="email">Email</label>
        <Input
          type="email"
          placeholder="Email"
          name="email"
          value={registerForm.email}
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="company">Company</label>
        <Input
          placeholder="Company"
          name="company"
          value={registerForm.company}
          onChange={handleChange}
        />
      </Form.Field>
      <Button primary type="submit" onClick={handleSumbit}>
        Submit
      </Button>
    </Form>
  ) : (
    <>
      <h3>Registration Form - Submitted</h3>
      <p>
        You've been added to the list. We'll let you know when new content is
        published.
      </p>
      <p>
        Want to register again? Click <ButtonLink onClick={showForm}>here</ButtonLink>.
      </p>
    </>
  )
}

export default RegisterForm
