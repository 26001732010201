import React from "react"
import { Icon, Flag } from "semantic-ui-react"

const CountryIcon = ({ report, countryName }) => {
  let returnItem
  countryName = countryName.toLowerCase()

  //Overrides
  countryName = countryName === "usa" ? "united states" : countryName

  if (countryName === "global") {
    returnItem = <Icon data-report={report} color="blue" name="globe" />
  } else if (countryName === "video") {
    returnItem = <Icon data-report={report} color="grey" name="video" />
  } else if (countryName === "europe") {
    returnItem = <Flag data-report={report} name="european union" />
  } else if (countryName === "north america") {
    returnItem = (
      <>
        <Flag data-report={report} name="united states" />
        <Flag data-report={report} name="canada" />
      </>
    )
  } else {
    returnItem = <Flag data-report={report} name={countryName} />
  }
  return returnItem
}

export default CountryIcon
