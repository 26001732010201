import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Grid, Divider } from "semantic-ui-react";
import styled from 'styled-components'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";


import HeroImage from "../components/images/HeroImage";
import {
  RegistrationForm,
  PaginatedTable,
  AboutUs,
} from "../components";

const HeaderBannerStyle = styled.div`
    text-transform: uppercase;
    background: radial-gradient(circle, rgb(44 91 142) 0%, rgb(44 91 142) 35%, rgb(35 71 112) 100%);
    color: #eef2f6;
    font-size: 2.3vmax;
    text-align: center;
    display: block;
    font-family: monospace;
    padding: 0.6em;
    /* padding: 20px; */
    letter-spacing: 0.05em;
    transform: scale(1,1.3em);
`

const IndexPage = ({ location, data }) => {
  //This is the priority ID for auto-sorting the content list when it comes from a targetted link
  const recentAnnouncements = JSON.parse(data.allContentfulContentBlocks.nodes.filter(block => block.name === 'Recent Announcements')[0].body.raw)
  console.log('recentAnnouncements', recentAnnouncements)
  return (
    <Layout>
      <Seo title="Home" />
      <Grid>
        <Grid.Row>
            <HeaderBannerStyle className='column'>Global Content Portal</HeaderBannerStyle>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} verticalAlign="middle">
            <HeroImage />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider></Divider>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={9}>
            <h3>Recent Releases</h3>
            <PaginatedTable edges={data.allContentJson.edges} />
          </Grid.Column>
          <Grid.Column width={7}>
            {documentToReactComponents(recentAnnouncements)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <AboutUs />
          </Grid.Column>
          <Grid.Column width={8}>
            <RegistrationForm key="homepageForm" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allContentJson: allContentfulContentPieces(
      sort: { fields: [releaseDate, sortingOverride], order: [DESC, ASC] }
    ) {
      totalCount
      edges {
        node {
          country
          date: releaseDate(formatString: "MMM D, 'YY")
          description {
            raw
          }
          name
          reportUrl: fileUrl
          tags
          slug
          id
        }
      }
    }
    allContentfulContentBlocks {
      nodes {
        body {
          raw
        }
        name
        plainTextBody {
          plainTextBody
        }
      }
    }
  }
`;
