import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const AboutUs = () => (
  <StaticQuery
    query={graphql`
      query aboutUs {
        contentfulContentBlocks(name: { eq: "AboutIMI" }) {
          id
          name
          body {
            raw
          }
        }
      }
    `}
    render={data =>
      documentToReactComponents(JSON.parse(data.contentfulContentBlocks.body.raw))
    }
  />
)

export default AboutUs
