import React, { useState } from "react";
import { Table, Pagination } from "semantic-ui-react";
import CountryIcon from "../components/CountryIcon";
import { useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const PaginatedTable = ({edges }) => {
  const data = useStaticQuery(graphql`
    query {
      result: contentfulContentBlocks(
        name: { eq: "Reports to Display Per Page" }
      ) {
        plainTextBody {
          plainTextBody
        }
      }
    }
  `);
  const pageSize = data.result.plainTextBody.plainTextBody || 10; //Get the pagesize from contentful content block
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(false);

  const array_chunks = (array, chunk_size) =>
    Array(Math.ceil(array.length / chunk_size))
      .fill()
      .map((_, index) => index * chunk_size)
      .map((begin) =>
        array.slice(Number(begin), Number(begin) + Number(chunk_size))
      );

  useEffect(() => {
    setPages(array_chunks(edges, pageSize));
  }, [edges, pageSize]);


  const handlePageChange = (event, data) => {
    setPage(data.activePage);
  };

  return (
    <div>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Report Name</Table.HeaderCell>
            <Table.HeaderCell singleLine>Released</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {pages &&
            pages[page - 1].map(({ node }) => {
              let countryName = node.country?.toString().toLowerCase();
              return (
                <Table.Row key={node.id}>
                  <Table.Cell>
                    <Link to={node.slug}>

                      <CountryIcon
                        key={"ci_" + node.id}
                        countryName={countryName}
                        report={node.name}
                      />
                      {node.name}&nbsp;
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="right">{node.date}</Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Pagination
        boundaryRange={0}
        defaultActivePage={1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
        totalPages={pages.length || 0}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default PaginatedTable;
