import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    query {
      #allContentfulHeroImage(sort: {fields: order, order: ASC}) {
      allContentfulHeroImage {
        nodes {
          title
          linkUrl
          image {
            gatsbyImage(width: 2000, quality: 90)
            title
            __typename
            file {
              url
            }
          }
        }
      }
      allContentfulContentBlocks(
        filter: {name: {in: ["Hero Image Header Text", "Hero Image Footer Text"]}}
      ) {
        nodes {
          name
          body {
            raw
          }
        }
      }
    }
  `);
  //console.log('data :>> ', data);
  const images = data.allContentfulHeroImage.nodes
  //console.log('images', images)
  const headerContent = data.allContentfulContentBlocks.nodes.filter(block => block.name === 'Hero Image Header Text')[0]
  const footerContent = data.allContentfulContentBlocks.nodes.filter(block => block.name === 'Hero Image Footer Text')[0]
   console.log('headerContent :>> ', JSON.parse(headerContent.body.raw));
   const headerJson = JSON.parse(headerContent.body.raw)

  const HeroImageItem = ({image, url, alt}) => {
    //console.log('image', image)
    // const newImage = getImage(image)
    // console.log('newImage', newImage)
    return (
      <a href={url} target='_blank' rel='noreferrer'>
        <GatsbyImage image={image.gatsbyImage} alt={alt} />
      </a>
    )
  }

  return (
    <>
      {documentToReactComponents(headerJson)}
      { images.map((image, index) => <HeroImageItem image={image.image} url={image.linkUrl} alt={image.title} key={index} />)}
      {documentToReactComponents(footerContent.body?.json)}
    </>
  )

};

export default HeroImage;


// query  {
//   allContentfulHeroImage(sort: {fields: order, order: ASC}) {
//     nodes {
//       title
//       image {
//         fluid(maxWidth: 2000, quality: 90) {
//           ...GatsbyContentfulFluid_withWebp_noBase64
//         }
//         title
//         file {
//           url
//         }
//       }
//     }
//   }
//   allContentfulContentBlocks(filter: {name: {in: ["Hero Image Header Text", "Hero Image Footer Text"]}}) {
//     nodes {
//       name
//       body {
//         json
//       }
//     }
//   }
// }